<template>
  <div id="cookie-box" v-if="seamless">
    <div class="cookie-box">
      <div class="container cookie-box-container">
        <div class="cookie-info">
          <div class="text-weight-bold cookie-info-title">
            {{ $t('usesCookies') }}
          </div>
          <div class="cookie-info-text">
            {{ $t('cookiesNavigation') }}
            <ui-link
              to="/cookie-policy"
              class="link cookie-link"
              color="primary"
            >
              {{ $t('hereButton') }}
            </ui-link>
            {{ $t('cookiesWantAllow') }}
          </div>
        </div>
        <div class="cookie-buttons">
          <ui-button
            v-show="isLoad"
            class="cookie-button-bordered"
            @click="fixed = true"
          >
            {{ $t('settings') }}
          </ui-button>
          <ui-button
            class="cookie-button-ok"
            color="primary"
            @click="closeCookieSidebar"
          >
            {{ $t('Ok') }}
          </ui-button>
        </div>
      </div>
    </div>
    <ui-modal v-model="fixed">
      <div class="column cookie-modal">
        <div class="cookie-modal-header">
          {{ $t('cookies') }}
        </div>
        <div class="column cookie-modal-checkbox">
          <ui-checkbox v-model="strictlyCookie" :disable="true">
            {{ $t('necessaryCookies') }}
          </ui-checkbox>
          <ui-checkbox v-model="performanceCookie">
            {{ $t('performanceCookies') }}
          </ui-checkbox>
          <ui-checkbox v-model="funcCookie">
            {{ $t('functionalityCookies') }}
          </ui-checkbox>
          <ui-checkbox v-model="marketingCookie">
            {{ $t('marketingCookies') }}
          </ui-checkbox>
        </div>
        <div class="cookie-modal-btn" @click="fixed = false">
          <ui-button class="cookie-button-bordered">
            {{ $t('back') }}
          </ui-button>
          <ui-button
            color="primary"
            class="cookie-button-ok"
            @click="acceptAction"
          >
            {{ $t('accept') }}
          </ui-button>
        </div>
      </div>
    </ui-modal>
  </div>
</template>
<script lang="ts" setup>
import {ref} from 'vue';
import {useCookieStore} from '~/modules/Cookies/cookie.store';
import UiButton from '~/components/Ui/UiButton.vue';
import UiModal from '~/components/Ui/UiModal.vue';
import UiCheckbox from '~/components/Ui/UiCheckbox.vue';

/**
 * временная функция, для более быстрого отображения модального окна с cookie
 * основная проблема, долгая загрузка чанков из за чего функции проверки и взаимодействия не могут быть выполнены
 * после оптимизации работы чанков, можно удалить useHead
 */
useHead({
  script: [
    {
      children: `
      const isNeedCookieModal = localStorage.getItem('accept_cookie') !== 'accept';
      const cookieBox = document.getElementById("cookie-box");
      if (cookieBox && isNeedCookieModal) {
        cookieBox.style.display  = "block"
        const btnOk = document.querySelector(".cookie-button-ok")
        const listener = function (event) {
            localStorage.setItem('accept_cookie', 'accept')
            localStorage.setItem('strictly_cookie', true)
            localStorage.setItem('performance_cookie', true)
            localStorage.setItem('functionality_cookie', true)
            localStorage.setItem('marketing_cookie', true)
            cookieBox.style.display  = "none"
            btnOk.removeEventListener('click', listener)
        }
        btnOk.addEventListener('click', listener)
      } else if (cookieBox) {
        cookieBox.style.display  = "none"
      }
    `,
      body: true,
      async: true,
    },
  ],
});

const cookie = useCookieStore();
cookie.getCookies();
const seamless = ref(true);
const fixed = ref(false);
const isLoad = ref(false);

let strictlyCookie = true;
const performanceCookie = ref(true);
const funcCookie = ref(true);
const marketingCookie = ref(true);

function closeCookieSidebar() {
  acceptAction();
}

const acceptAction = () => {
  seamless.value = false;
  cookie.acceptCookies(
    strictlyCookie,
    performanceCookie.value,
    funcCookie.value,
    marketingCookie.value,
  );
};

onMounted(() => {
  isLoad.value = true;
  seamless.value = localStorage.getItem('accept_cookie') !== 'accept';
});
</script>
<style lang="scss" scoped>
.cookie-box {
  width: 100%;
  border-radius: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2),
    0px -4px 12px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;

  .cookie-box-container {
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .cookie-info {
    width: 70%;

    .cookie-info-title {
      font-size: 18px;
      margin-bottom: 16px;
    }

    .cookie-info-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: 0.03em;
      color: #3d424a;

      .cookie-link {
        text-decoration: none;
        color: $primary;
      }
    }
  }
  .cookie-buttons {
    display: flex;
  }
  .cookie-buttons > * {
    border-radius: 6px;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    padding: 6px 24px;
  }
}

.cookie-modal {
  background-color: #ffffff;
  padding: 24px;
  width: 450px;
  line-height: 24px;

  .cookie-modal-header {
    margin-bottom: 19px;
    font-weight: 700;
    font-size: 18px;
  }

  .cookie-modal-checkbox {
    color: #3d424a;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .cookie-modal-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }

  .cookie-modal-btn > * {
    border-radius: 6px;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.02em;
    padding: 6px 24px;
  }
}

.cookie-button-bordered {
  box-shadow: none;
  border: 1px solid $primary;
  color: $primary;
}

.cookie-button-ok {
  margin-left: 16px;
}

@media (min-width: 600px) {
  .q-dialog__inner--minimized > div {
    max-width: 100%;
  }
}

@media (max-width: 590px) {
  .cookie-box {
    width: 100%;

    .cookie-box-container {
      padding: 24px 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: column;
    }

    .cookie-info {
      width: 100%;
    }

    .cookie-buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 24px;
    }
  }

  .cookie-modal {
    width: 90%;
  }
}
</style>
